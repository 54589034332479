<template>
  <div class="">
    <section class="pie backverde2 container-fluid" v-if="!FOOTER[0]">
      <div class="ovalo">
        <img
            class="logo2"
            src="../../assets/imagenes/footer/logo_lorenita.png"
            alt="Lorena"
        />
      </div>
    </section>
    <div :class="'cajapielore container-fluid '+ FOOTER[1]" v-if="FOOTER[0]">
      <div class="row container-fluid ps-0 pe-0">
        <div class="col-7">
          <div class="ovalolore">
            <img class="logo3" src="../../assets/imagenes/footer/logo_lorenita.png" alt="Firma Lorena">
          </div>
        </div>
        <div class="col-5 pe-0">
          <router-link style="text-decoration: none" to="/">
            <div class="ruedalore">
              <img class="casai" src="../../assets/imagenes/footer/Casita .png" alt="">
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: 'Footer',
  computed: {
    ...mapGetters(["FOOTER"]),
  }
};
</script>
